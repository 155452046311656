<template>
  <div class="world login">
    <div class="login-wrapper">
      <div class="title">
        <h1>{{ $t('Wilms World - Login') }}</h1>
        <div class="intro">
          <p>{{ $t('Gelieve in te loggen om gebruik te kunnen maken van het Wilms World platform.') }}</p>
        </div>
      </div>

      <formulate-form :form-errors="formErrors" ref="loginForm" name="loginForm" v-model="formValues" @submit="login">
        <formulate-input validation="required" error-behavior="submit" type="email" name="username" validation-name="E-mailadres" placeholder="E-mailadres"></formulate-input>
        <formulate-input validation="required" error-behavior="submit" type="password" name="password" validation-name="Wachtwoord" placeholder="Wachtwoord"></formulate-input>
        <formulate-input type="submit" :label="isLoading ? $t('Even geduld...') : $t('Inloggen')"></formulate-input>
        <formulate-errors></formulate-errors>
      </formulate-form>

      <router-link class="reset-link" :to="{name: 'ww.passwordreset'}">{{ $t('Wachtwoord vergeten? Klik hier.') }}</router-link>

      <div class="no-account-info">
        <h3>{{ $t('Nog geen Wilms World account?') }}</h3>
        <a class="btn" href="https://www.wilms.be/nl/professional/contact?type=ask-for-login">{{ $t('Vraag account aan') }}</a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      isLoading: false,
      formValues: {},
      formErrors: [],
      redirectUrl: null
    }
  },
  mounted () {
    if (this.$route.query.redirectUrl) {
      this.redirectUrl = this.$route.query.redirectUrl
    }
  },
  methods: {
    async login () {
      this.isLoading = true
      const loginForm = new FormData()
      loginForm.append('username', this.formValues.username)
      loginForm.append('password', this.formValues.password)

      const data = {
        formData: loginForm,
        redirectUrl: this.redirectUrl
      }

      const response = await this.$store.dispatch('login', data)

      if (this.$store.getters.loggedIn) {
        if (this.redirectUrl) {
          window.location.href = this.redirectUrl + '?access_token=' + this.$store.state.access_token + '&refresh_token=' + this.$store.state.refresh_token
        } else {
          if (localStorage.getItem('redirectName')) {
            const params = JSON.parse(localStorage.getItem('redirectParams'))
            await this.$router.push({ name: localStorage.getItem('redirectName'), params: params })
            localStorage.removeItem('redirectName')
            localStorage.removeItem('redirectParams')
          } else {
            await this.$router.push({ name: 'ww.dashboard' })
          }
        }
        this.isLoading = false
      } else {
        this.formErrors = [this.$t(response.response.data.detail)]
        this.isLoading = false
      }
    }
  }
}
</script>
